import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Image from '../components/Image'
import Layout from '../components/Layout'
import Cartotheque from '../components/Cartotheque'
import Card from '../components/Card'

const BlogIndex = ({ data }) => {
  const seo = {
    title: 'Blog',
    slug: 'blog',
    image: data.seoImage,
    description: 'Reports and reflections on Unsworn activities.', 
  }

  return (
    <Layout seo={seo}>
      <article>
        <h1>The Unsworn Blog</h1>
        <p>Fresh, factory-fabricated news, thoughts and learnings</p>
        <Cartotheque>
          {data.posts.edges.map(({ node: post }) => {
            return (
              <Card
                size={post.frontmatter.cardSize}
                link={`/blog/${post.frontmatter.year}/${post.frontmatter.slug}`}
                key={post.frontmatter.slug}
              >
                <Image
                  data={post.frontmatter.image}
                  alt={post.frontmatter.title}
                />
                <h2>{post.frontmatter.title}</h2>
                <p>{post.frontmatter.date}</p>
                <p>{post.frontmatter.summary}</p>
              </Card>
            )
          })}
        </Cartotheque>
      </article>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    seoImage: file(relativePath: { eq: "unsworn/telemegaphone/making_of_2008/tmd_skulpturgrupp.jpg" }) {
      ...seoImage
    }
    posts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/content/posts//" }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            cardSize
            slug
            summary
            title
            date(formatString: "YYYY-MM-DD")
            year: date(formatString: "YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
`
